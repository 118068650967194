var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('p',[_vm._v("Current version: "),_c('strong',[_vm._v(_vm._s(_vm.agreements[0]? 'v'+_vm.agreements[0].version : 'none'))]),_vm._v(" updated "),_c('strong',[_vm._v(_vm._s(_vm.agreements[0]? _vm.formatDate(_vm.agreements[0].created_at) : 'n/a'))])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('editor',{attrs:{"id":"content","rows":"20","trim":"","autocomplete":"off","api-key":"o8d3io3d7ss0ytxqb5q82vp9rk2jbsuowdt2wgcao1iwd9rd","init":{
              height: 500,
              menubar: false,
              invalid_elements : 'img,iframe,canvas,script,style,pre',
              block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
              plugins: ['code','link','table','paste','lists'],
              smart_paste: false,
              toolbar: [
                'undo redo | removeformat | formatselect | bold italic | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | link unlink | table tabledelete | help | code' ],
              table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
            }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Save v"+_vm._s(_vm.agreements[0]? _vm.agreements[0].version+1 : 1)+" of agreement ")]),_c('span',{staticClass:"text-warning"},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon"}}),_vm._v(" This will replace the current agreement and ask all users if they accept the new agreement.")],1)],1)],1)],1)]}}])}),(_vm.agreements[0])?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h3',{staticClass:"mb-2"},[_vm._v(" Previous versions ")]),_c('b-table',{attrs:{"items":_vm.agreements,"fields":_vm.tableColumns,"hover":"","striped":""},scopedSlots:_vm._u([{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.value))+" ")]}}],null,false,3560297634)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }