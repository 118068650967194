<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- User Info: Input Fields -->
      <b-form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="mt-1">
          <b-col>
            <p>Current version: <strong>{{ agreements[0]? 'v'+agreements[0].version : 'none' }}</strong> updated <strong>{{ agreements[0]? formatDate(agreements[0].created_at) : 'n/a' }}</strong></p>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Body -->
          <b-col
            cols="12"
            md="12"
          >
            <editor
              id="content"
              v-model="content"
              rows="20"
              trim
              autocomplete="off"
              api-key="o8d3io3d7ss0ytxqb5q82vp9rk2jbsuowdt2wgcao1iwd9rd"
              :init="{
                height: 500,
                menubar: false,
                invalid_elements : 'img,iframe,canvas,script,style,pre',
                block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6;',
                plugins: ['code','link','table','paste','lists'],
                smart_paste: false,
                toolbar: [
                  'undo redo | removeformat | formatselect | bold italic | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | link unlink | table tabledelete | help | code',

                ],
                table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
              }"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
            >
              Save v{{ agreements[0]? agreements[0].version+1 : 1 }} of agreement
            </b-button>
            <span class="text-warning"><feather-icon icon="AlertTriangleIcon" /> This will replace the current agreement and ask all users if they accept the new agreement.</span>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row
      v-if="agreements[0]"
      class="mt-3"
    >
      <b-col>
        <h3 class="mb-2">
          Previous versions
        </h3>
        <b-table
          :items="agreements"
          :fields="tableColumns"
          hover
          striped
        >
          <template #cell(created_at)="data">
            {{ formatDate(data.value) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BForm, BTable,
} from 'bootstrap-vue'
import store from '@/store'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Editor from '@tinymce/tinymce-vue'
import formatDateMixin from '@core/mixins/ui/date'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BForm,
    BTable,
    // Form Validation
    ValidationObserver,

    Editor,
  },
  mixins: [formatDateMixin],
  data() {
    return {
      content: '',
      agreements: [],
    }
  },
  created() {
    this.fetchAgreements()
  },
  methods: {
    onSubmit() {
      store.dispatch('app/createAgreement', {
        content: this.content,
      }).then(() => {
        this.fetchAgreements()
        this.$toast.success({
          component: ToastificationContent,
          props: {
            title: 'Success',
            text: 'New agreement have been saved',
            variant: 'Success',
          },
        })
      })
        .catch(error => {
          this.$toast.error({
            component: ToastificationContent,
            props: {
              title: 'Error updating agreement',
              text: error,
              variant: 'Danger',
              icon: 'AlertTriangleIcon',
            },
          })
        })
    },
    fetchAgreements() {
      store.dispatch('app/fetchAgreements')
        .then(response => {
          this.agreements = response.data.results
          this.content = response.data.results[0].content
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.agreements = undefined
          }
        })
    },
  },
  setup() {
    const tableColumns = [
      { label: 'Version', key: 'version', sortable: false },
      { label: 'Updated', key: 'created_at', sortable: false },
    ]

    return {
      tableColumns,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
pre{
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: .5rem;
}
</style>
